import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AllProjectListInterface,
  CurrentProjectIdInterface,
  IInitialDimensions,
  IProjectMember,
  ProjectFormInterface,
  ProjectInterface,
} from "./types";
import { initialState } from "./types/initialState";

export const useClinicSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setDashboardBoxDimensions: (state, action: PayloadAction<IInitialDimensions>) => {
      state.dashboardBoxDimensions = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setProjectDelete: (state, action: PayloadAction<boolean>) => {
      state.projectDelete = action.payload;
    },
    toggleShowAllowPermissions: (state, action: PayloadAction<boolean>) => {
      state.allowPermissions = action.payload;
    },
    toggleShowProjectSetting: (state, action: PayloadAction<boolean>) => {
      state.showSetting = action.payload;
    },
    optionBarManageProjectToggle: (state, action: PayloadAction<boolean>) => {
      state.optionBarManageProject = action.payload;
    },

    setProjectLoading: (state, action: PayloadAction<boolean>) => {
      state.projectLoading = action.payload;
    },
    setProjectOpenLoading: (state, action: PayloadAction<boolean>) => {
      state.projectOpenLoading = action.payload;
    },
    setProjectListLoading: (state, action: PayloadAction<boolean>) => {
      state.projectListLoading = action.payload;
    },
    setSharedProjectListLoading: (state, action: PayloadAction<boolean>) => {
      state.sharedProjectListLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleManageProject: (state, action: PayloadAction<boolean>) => {
      state.manageProject = action.payload;
    },

    toggleOpenCreateProject: (state) => {
      state.showCreateProject = !state.showCreateProject;
    },
    toggleShowCreateProject: (state, action: PayloadAction<boolean>) => {
      state.showCreateProject = action.payload;
    },
    toggleOpenEditCreateProject: (state) => {
      state.showEditProject = !state.showEditProject;
    },
    updateProjectFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `projectForm.${action.payload.key}`, action.payload.value);
    },
    clearProjectFrom: (state) => {
      state.projectForm._id = "";
      state.projectForm.name = "";
      state.projectForm.description = "";
      state.projectForm.newCommentNotification = true;
      state.projectForm.newMediaNotification = false;
      state.projectForm.addUserPermission = false;
      state.projectForm.canDownloadPermission = true;
      state.projectForm.search = "";
      state.projectForm.projectMember = [];
      state.projectForm.projectIcon = "";
    },
    setProjectDataForEdit: (
      state,
      action: PayloadAction<ProjectFormInterface>
    ) => {
      state.projectForm = action.payload;
    },
    doAddProject: (
      state,
      action: PayloadAction<{
        callback: (responseData: { projectId: string; fileId: string }) => void;
      }>
    ) => {},
    doGetMyProjectList: (state) => {},
    doGetProjectEdit: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doUpdateProject: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setProjectList: (state, action: PayloadAction<Array<ProjectInterface>>) => {
      state.projectsList = action.payload;
    },
    setIsContentExist: (state, action: PayloadAction<boolean>) => {
      state.isContentExist = action.payload;
    },
    setIsProjectExist: (state, action: PayloadAction<boolean>) => {
      state.isProjectExist = action.payload;
    },
    setIsDeactivated: (state, action: PayloadAction<boolean>) => {
      state.isDeactivated = action.payload;
    },
    setProjectDowngrade: (state, action: PayloadAction<boolean>) => {
      state.projectDowngrade = action.payload;
    },
    setProjectInActiveDate: (state, action: PayloadAction<string>) => {
      state.projectInActiveDate = action.payload;
    },
    doCreateFolder: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    //
    doGetAllProjectList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setAllProjectList: (
      state,
      action: PayloadAction<Array<AllProjectListInterface>>
    ) => {
      state.allProjectList = action.payload;
    },
    doDeleteProject: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doLeaveProject: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetSharedWithList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setSharedWithList: (state, action: PayloadAction<ProjectFormInterface>) => {
      state.projectForm = action.payload;
    },
    doResendProjectInvitation: (
      state,
      action: PayloadAction<{ id: string; email: string; callback: () => void }>
    ) => {},
    doRemoveUser: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      // state.sharedWithList[action.payload.index].remove = true;
    },
    doManageProjectUsers: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setCurrentProjectId: (
      state,
      action: PayloadAction<CurrentProjectIdInterface>
    ) => {
      state.currentProjectId = action.payload;
      window.sessionStorage.setItem("projectId", action.payload.projectId);
      window.sessionStorage.setItem("fileId", action.payload.fileId);
    },
    clearCurrentProjectId: (state) => {
      state.currentProjectId = null;
    },
    toggleProjectSwitchLoading: (state, action: PayloadAction<boolean>) => {
      state.projectSwitchLoading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setPageSizeOnWindowChange: (state, action: PayloadAction<number>) => {
      if (state.pageSize !== action.payload) {
        state.pageSize = action.payload;
        state.pageNo = 1;
      }
    },
    editProjectMemberAccess: (
      state,
      action: PayloadAction<{
        Index: number;
        value: any;
      }>
    ) => {
      state.manageProjectUsers.projectMember[action.payload.Index].type =
        action.payload.value;
    },
    createProjectMemberAccess: (
      state,
      action: PayloadAction<{
        Index: number;
        value: any;
      }>
    ) => {
      state.projectForm.projectMember[action.payload.Index].type =
        action.payload.value;
    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {},
    setUserDataForEdit: (state, action: PayloadAction<IProjectMember>) => {
      if (!state.projectForm.projectMemberForm) {
        state.projectForm.projectMemberForm = JSON.parse(
          JSON.stringify(initialState.projectForm.projectMemberForm)
        );
      }
      state.projectForm.projectMemberForm._id = action?.payload?._id;
      state.projectForm.projectMemberForm.email = action?.payload?.email;
      state.projectForm.projectMemberForm.name = action?.payload?.name;
      state.projectForm.projectMemberForm.type = action.payload.type;
      state.projectForm.projectMemberForm.profilePic =
        action.payload.profilePic;
      state.projectForm.projectMemberForm.userStatus =
        action.payload.userStatus;
      state.projectForm.projectMemberForm.isNew = action.payload.isNew;
      // state.projectForm.projectMember.push(action.payload)
    },

    clearAddUserFrom: (state) => {
      state.projectForm.search = "";
    },
    doAddUser: (state) => {
      state.projectForm.projectMember.push({
        _id: state.projectForm.projectMemberForm._id,
        email: state.projectForm.projectMemberForm.email,
        name: state.projectForm.projectMemberForm.name,
        type: state.projectForm.projectMemberForm.type,
        profilePic: state.projectForm.projectMemberForm.profilePic,
        isNew: state.projectForm.projectMemberForm.isNew,
        userStatus: state.projectForm.projectMemberForm.userStatus,
      });
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
