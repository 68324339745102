import { DashboardState, ProjectMemberTypeUpdateRequestedEnum } from ".";
import { TeamMemberActiveStatusEnum } from "../../AccountSettings/ManageTeam/types";

export const initialState: DashboardState = {
  loading: false,
  projectLoading: false,
  projectOpenLoading: false,
  projectListLoading: false,
  sharedProjectListLoading: false,
  showCreateProject: false,
  showEditProject: false,
  projectForm: {
    _id: "",
    name: "",
    description: "",
    newCommentNotification: true,
    newMediaNotification: false,
    addUserPermission: true,
    canDownloadPermission: false,
    projectIcon: "",
    projectMember: [],
    search: "",
    projectMemberForm: {
      _id: "",
      email: "",
      name: "",
      isInvited: false,
      invitationExpired: false,
      userStatus: TeamMemberActiveStatusEnum.ACTIVE,
      type: ProjectMemberTypeUpdateRequestedEnum.COLLABORATOR,
      profilePic: "",
      isNew: false,
    },
  },
  projectsList: [],
  search: "",
  allProjectList: [],
  isContentExist: false,
  isProjectExist: false,
  isDeactivated: false,
  manageProject: false,
  manageProjectUsers: {
    _id: "",
    name: "",
    projectMember: [],
    projectMemberCount: 0,
  },
  currentProjectId: {
    projectId: sessionStorage.getItem("projectId") as string,
    fileId: sessionStorage.getItem("fileId") as string,
  },
  projectSwitchLoading: false,
  projectDowngrade: false,
  projectInActiveDate: "",
  pageNo: 1,
  pageSize: 5,
  totalRow: 0,
  hasMore: false,
  showSetting: false,
  buttonLoading: false,
  projectDelete: false,
  allowPermissions: false,
  optionBarManageProject: false,
  dashboardBoxDimensions: { height: 0, width: 0 },
};
