import { TeamMemberActiveStatusEnum } from "../../AccountSettings/ManageTeam/types";
import { UserProjectRoleEnum } from "../FileAndFolder/types";
import { ProjectMemberTypeEnum } from "../SharedProject/types";

export interface ProjectFormInterface {
  _id: string;
  name: string;
  description: string;
  newCommentNotification: boolean;
  newMediaNotification: boolean;
  addUserPermission: boolean;
  canDownloadPermission: boolean;
  projectIcon: string;
  projectMember: IProjectMember[];
  projectMemberForm: IProjectMember;
  search: string;
}
export enum ThumbnailFileStatusEnum {
  IN_PROGRESS = 1,
  PROCESSED = 2,
  FAILED = 3,
}
export enum ProjectStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}

export interface ProjectInterface {
  _id: string;
  name: string;
  description: string;
  fileName: string;
  fileId: string;
  isActive: ProjectStatusEnum;
  createdAt: string;
  thumbnailFileStatus: ThumbnailFileStatusEnum;
  projectThumbnailPath: string;
  ownerName: string;
  inactiveDate: string;
  projectCreated: boolean;
  isShared: boolean;
  userRole?: ProjectMemberTypeEnum;
}
export interface TeamInterface {
  _id: string;
  name: string;
  projects: ProjectInterface[];
}
export interface AllProjectListInterface {
  _id: string;
  project: ProjectInterface[];
  shareProject: ProjectInterface[];
  teams: TeamInterface[];
  shareTeam: TeamInterface[];
}
export interface IProjectMember {
  _id: string;
  email: string;
  name: string;
  isInvited?: boolean;
  invitationExpired?: boolean;
  userStatus: TeamMemberActiveStatusEnum;
  type: ProjectMemberTypeUpdateRequestedEnum;
  profilePic: string;
  isNew: boolean;
}
export interface SharedWithInterface {
  _id: string;
  name: string;
  projectMember: IProjectMember[];
  projectMemberCount: number;
}
export enum ProjectMemberTypeUpdateRequestedEnum {
  COLLABORATOR = 1,
  VIEWER = 2,
  EDITOR = 3,
  REMOVE = 4,
}
export enum accessType {
  ONLY_INVITED = 1,
  PASSWORD_PROTECTED = 2,
}
export interface CurrentProjectIdInterface {
  projectId: string;
  fileId: string;
}
export interface IInitialDimensions {
  height: number;
  width: number;
}
export interface DashboardState {
  loading: boolean;
  projectListLoading: boolean;
  sharedProjectListLoading: boolean;
  projectLoading: boolean;
  buttonLoading: boolean;
  search: string;
  projectForm: ProjectFormInterface;
  projectsList: ProjectInterface[];
  allProjectList: AllProjectListInterface[];
  showCreateProject: boolean;
  showEditProject: boolean;
  isContentExist: boolean;
  manageProject: boolean;
  manageProjectUsers: SharedWithInterface;
  currentProjectId: CurrentProjectIdInterface | null;
  isProjectExist: boolean;
  isDeactivated: boolean;
  projectSwitchLoading: boolean;
  projectOpenLoading: boolean;
  projectDowngrade: boolean;
  projectInActiveDate: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  hasMore: boolean;
  showSetting: boolean;
  projectDelete: boolean;
  allowPermissions: boolean;
  dashboardBoxDimensions: IInitialDimensions;
  optionBarManageProject: boolean;
}

export type InitialDashboardState = DashboardState;
