import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.dashboardState) {
    return state.dashboardState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectProjectLoading = createSelector(
  [selectDomain],
  (state) => state.projectLoading
);
export const selectProjectListLoading = createSelector(
  [selectDomain],
  (state) => state.projectListLoading
);
export const selectSharedProjectListLoading = createSelector(
  [selectDomain],
  (state) => state.sharedProjectListLoading
);

export const selectShowProject = createSelector(
  [selectDomain],
  (state) => state.showCreateProject
);
export const selectShowEditProject = createSelector(
  [selectDomain],
  (state) => state.showEditProject
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectProjectForm = createSelector(
  [selectDomain],
  (state) => state.projectForm
);
export const selectMyProjectList = createSelector(
  [selectDomain],
  (state) => state.projectsList
);

export const selectAllProjectList = createSelector(
  [selectDomain],
  (state) => state.allProjectList
);

export const selectIsContentExist = createSelector(
  [selectDomain],
  (state) => state.isContentExist
);
export const selectIsProjectExist = createSelector(
  [selectDomain],
  (state) => state.isProjectExist
);
export const selectIsDeactivated = createSelector(
  [selectDomain],
  (state) => state.isDeactivated
);
export const selectSharedWithList = createSelector(
  [selectDomain],
  (state) => state.manageProjectUsers
);
export const selectManageProject = createSelector(
  [selectDomain],
  (state) => state.manageProject
);
export const selectCurrentProjectId = createSelector(
  [selectDomain],
  (state) => state.currentProjectId
);
export const selectProjectSwitchLoading = createSelector(
  [selectDomain],
  (state) => state.projectSwitchLoading
);
export const selectProjectOpenLoading = createSelector(
  [selectDomain],
  (state) => state.projectOpenLoading
);
export const selectProjectDowngrade = createSelector(
  [selectDomain],
  (state) => state.projectDowngrade
);
export const selectProjectInActiveDate = createSelector(
  [selectDomain],
  (state) => state.projectInActiveDate
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);

export const selectShowSetting = createSelector(
  [selectDomain],
  (state) => state.showSetting
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectProjectDelete = createSelector(
  [selectDomain],
  (state) => state.projectDelete
);
export const selectAllowPermissions = createSelector(
  [selectDomain],
  (state) => state.allowPermissions
);
export const selectDashboardBoxDimensions = createSelector(
  [selectDomain],
  (state) => state.dashboardBoxDimensions
);
export const selectOptionBarManageProject = createSelector(
  [selectDomain],
  (state) => state.optionBarManageProject
);
